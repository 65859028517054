//
// Indicator
//

.indicator-progress {
    display: inline-block;
}

.indicator-label {
  display: inline-block;
}
