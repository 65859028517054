.form-control.form-control-rounded, .form-control.rounded {
    border-radius: 20px;
  }
  .form-select.form-select-rounded, .form-select.rounded {
    border-radius: 20px;
  }
  .btn.btn-rounded, .btn.rounded {
    border-radius: 20px;
  }
  
  .card{
    max-width: 100% !important;
    .card-body{
      max-width: 100% !important;
      .dataTables_wrapper{
        max-width: 100% !important;
  
        .table-responsive{
          max-width: 100% !important;
  
          table{
            max-width: 100% !important;
  
          }
        }
      }
    }
  }
  
  
  /************** TOAST STYLE ************/
  
  .ngx-pagination {
    li a, .current {
      border-radius: 8px !important;
      // padding: 4.5px 12px !important;
      padding-top: 4.5px !important;
      padding-bottom: 4.75px !important;
      min-width: 30px !important;
      margin: 3.5px !important;
      text-align: center !important;
    }
  
  }

  .mini-circle{
    width: 20px !important;
    height: 20px !important;
    border-radius: 100% !important;
    /* background: #009e5f !important; */
    border: 1px solid rgba(128, 128, 128, 0.25) !important;
  }