@import "./assets/sass/toastr.scss";

@import "./assets/sass/style";

@import "./assets/sass/style.angular.scss";
@import "./assets/sass/global.scss";
body {
  --entity-primary: rgba(0, 0, 0, 0.32);
}
.btn-primary{
  background-color: var(--entity-primary) !important;
  color: white;
}
body{
  font-size: 15px!important;
  color: black !important;
}
.btn.btn-light-primary {
  color: #fff;
  border-color: #a9a6a6;
  background-color: #a9a6a6;
}
.btn.btn-light-primary i, .btn.btn-light-primary .svg-icon {
  color: #ffffff;
}
.btn-primary:hover{
  background-color: var(--entity-primary) !important;
}
.toolbar {
  display: flex;
  align-items: center;
  color: white;
  background: linear-gradient(to right, #3d3939, white);
  padding: 0.5rem 0;
}
.ng-select.ng-select-single .ng-select-container {
background-color: #F5F8FA !important;
border-color: #F5F8FA !important;
  height: 42px !important;
}
.flag { background-image: url('/assets/media/flags/flags.jpeg') !important; }
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #ced4da !important;
  border-right: none !important;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border: none !important;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background:  var(--entity-primary);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderPage{
 text-align: center
}
.styleTabVide{
  text-align: center;
  color: rgba(0, 0, 0, 0.37);
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #4caf50;
}
